import React, { useContext } from "react";
import Head from "next/head";
import { LoginForm } from "components/Forms/Login";
import st from "./signIn.module.scss";
import { META_TITLE, CANONICAL_URL_BASE } from "constants/meta";
import { useRedirectIfLoggedIn } from "hooks/useRedirectIfLoggedIn";
import { LogContext } from "context/log";
import { PAGE_TYPE_ENUM } from "@pepdirect/shared/types";
import { generatePageId } from "@pepdirect/helpers/analyticsLogger";

export const getServerSideProps = async (): Promise<unknown> => ({ props: {} });

export default function SignIn(): JSX.Element {
  const { useLogPageView } = useContext(LogContext);
  const title = `Login to ${META_TITLE}`;
  useLogPageView({
    pageType: PAGE_TYPE_ENUM.account,
    pageTitle: title,
    pageCategory: undefined,
    id: generatePageId("sign-in"),
  });

  useRedirectIfLoggedIn("/home");
  return (
    <section className={st.wrapper}>
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={`${CANONICAL_URL_BASE}/forgot-password`} />
      </Head>
      <LoginForm />
    </section>
  );
}
