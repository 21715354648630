import { useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { UserContext } from "context/user";

export function useRedirectIfLoggedIn(link = "/"): void {
  const { currentUserId } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (currentUserId) {
      if (router.query.redirect) {
        router.push(
          (router.query.redirect as string) || "/home",
          (router.query.redirectAs as string) || "/home"
        );
      } else {
        router.push(link);
      }
    }
  }, [currentUserId, router, link]);
}
